<template>
  <div>

    <!-- Course Title Block-->
    <v-sheet class="d-flex" :color="wsLIGHTCARD">
      <v-sheet max-width="1240" width="100%" color="transparent" class="px-2 mx-auto mb-15"    >
        <portal-target name="noImageCourse"></portal-target>

        <v-row style="overflow-y: hidden" no-gutters>
          <v-col :cols="SM ? 12 : 7"   >
            <portal :disabled="entity.image" to="noImageCourse">

              <router-link :to="businessPublicLink('')" class="noUnderline">
                <v-sheet
                    style="border-radius: 50%"
                    class="d-flex align-center justify-center my-8"
                    width="62" height="64" :style="`border : 1px solid ${wsACCENT} !important`" color="transparent">
                  <v-icon :color="wsACCENT">mdi-arrow-left</v-icon>
                </v-sheet>
              </router-link>
              <h2 :style="`color : ${wsACCENT}; font-size: ${!SM ? 19 : 19}px`" class="mb-5"
              >
                {{ $store.state.business.selectedBusiness.name }}
              </h2>

              <div
                  v-if="entity.image && SM"
                  style="width : 100%; height: 100%;"
                  class="d-flex justify-center mb-8" >
                <v-responsive aspect-ratio="1.3">
                  <img
                      :src="entity.image"
                      height="100%"
                      width="100%"
                      style="border-radius: 25px; margin-left: auto; margin-right: auto; object-fit: cover"

                  />
                </v-responsive>

              </div>

              <v-divider :style="`border-color : ${wsBACKGROUND}`" />

            </portal>

            <h1 :style="`color : ${wsDARKER};font-size: ${!SM ? 42 : 34}px`" class="mt-10">
              {{ entity.name }}
            </h1>
            <h3
                v-if="entity.image"
                :style="`color : ${wsDARKER}`"
                class="font-weight-regular mt-5"
                style="font-size: 18px"
            >
              {{  entity.description  }}
            </h3>

            <h3     style="font-size: 18px" class="wsACCENT my-4 font-weight-regular">Початок : {{  $time(entity.date_start).regularTime()  }}</h3>
            <div class="d-flex align-center ">
              <ws-button left-icon="mdi-basket-plus-outline" label="BuyWebinar" class="mr-2"></ws-button>
              <h3>{{ entity.price }}</h3>
            </div>

            <view-button
                class="mt-5"
                v-if="entity.image && entity.uuid"
                :element="button"
                block-style="light_1"
                padding-x="20"
                padding-y="8"
                :block="block"
                flex
            />
          </v-col>
          <v-col cols="12" sm="5"  >

            <div v-if="!entity.image">
              <h3
                  :style="`color : ${wsDARKER}`"
                  class="font-weight-regular mt-14"
                  style="font-size: 18px"
              >
                {{  entity.description  }}
              </h3>
              <view-button
                  class="mt-5"
                  v-if="entity.uuid"
                  :element="button"
                  block-style="light_1"
                  padding-x="20"
                  padding-y="8"
                  :block="block"
                  flex
              />

            </div>
            <div v-else-if="!SM" class="d-flex justify-end ">
              <img
                  :src="entity.image"
                  style="border-radius: 50%; margin-left: auto; margin-top : -32px; object-fit: cover"
                  height="472"
                  width="472"
              />
            </div>


          </v-col>

        </v-row>


      </v-sheet>

    </v-sheet>


    <v-sheet max-width="800" width="100%" color="transparent" class="px-2 mx-auto mb-15"    >
    <ws-text-viewer
        class="mt-5"
        :value="entity.full_description"
        is-public
    />
    </v-sheet>

    <!-- Contact Form -->
    <v-sheet :class="[{'d-flex' : !SM}]" class="justify-center" :color="wsBACKGROUND" >
      <v-sheet max-width="1240" width="100%" color="transparent" class="px-2 d-flex align-center justify-center"
               style="padding-top: 75px; padding-bottom: 75px"
      >
        <div>
          <h1 class="text-center"   :style="!SM ? 'width : 400px' : null">
            {{ $t("avalon.default.contact_form_title") }}
          </h1>
          <viewForm
              class="mt-8"
              :style="!SM ? 'width : 400px' : null"
              block-style="light_1"
              :block="{ config : { style : 'light_1' } } "
          />

        </div>





      </v-sheet>
    </v-sheet>


  </div>
</template>

<script>
import {mapActions} from "vuex";
import viewForm from "@/components/AvalonEditor/View/elements/viewForm.vue";
import viewButton from "@/components/AvalonEditor/View/elements/button/viewButton.vue";
// import viewBlock from "@/components/AvalonEditor/View/blocks/viewBlock.vue";

export default {
  name: "WebinarPublicDefault",
  props : {
    webinarUuid : {}
  },
  components : {
    viewForm,
    viewButton,
    // viewBlock
  },
  data() {
    return {
      entity : {},
      business : {},
      button : {
        config : {
          action : 'enroll_on_course',
          style: 'primary',
          rounded : 4
        }
      },
    }
  },
  methods : {
    ...mapActions('webinars', [
      'GET_ONE_WEBINAR_PUBLIC'
    ]),
    ...mapActions('market', [
      'GET_BUSINESS_MARKET'
    ]),

    async initPage() {
      this.business = await this.GET_BUSINESS_MARKET(this.$store.state.business.selectedBusiness.alias)
      const result = await this.GET_ONE_WEBINAR_PUBLIC({ uuid: this.webinarUuid } );


      if (!result) {
        this.ERROR();
        return
      }

      this.entity = await result;


    },
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>